import { Injectable } from '@angular/core';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { AuditDraftFindingConversationDetailsDTO } from '../dto/audit-draft-finding-conversation.dto';
import { FileConversion } from '../../../../shared/conversion/file.converstion.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditDetailsDraftFindingConversationConversion
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
    private fileConv: FileConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): AuditDraftFindingConversationDetailsDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        type: item.type,
        commented_user: this.userConv.resToUserMinimalIdFixedDTO(item, 'created_user'),
        comment: item.comment,
        commented_at: item.created_at,
        documents: this.fileConv.resToFileDetailsDTOArray(item.documents),
      
      };
    });
    return result;
  }

  resToDetails(response: any): AuditDraftFindingConversationDetailsDTO {
    return {
      id: response.id,
      type: response.type,
      comment: response.comment,
      commented_user: this.userConv.resObjToUserMinimalDTO(response.created_by_user),
      commented_at: response.created_at,
      documents: this.fileConv.resToFileDetailsDTOArray(response.documents),
    };
  }

}
