import { Injectable } from '@angular/core';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import { AuditInsightDraftReportFindingActionPlanDetailsDTO, AuditInsightDraftReportFindingActionPlanFormDTO, AuditInsightDraftReportFindingChallengeFormDTO } from '../dto/audit-insight-draft-report-finding-action.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditInsightDraftReportFindingActionConversion
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }


  formGroupToForm(formGroup: FormGroup): AuditInsightDraftReportFindingActionPlanFormDTO {
    return {
      comment: formGroup.get('Comment')?.value,
      type: "DR",
      title: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      responsible_user_id: formGroup.get('ResponsibleUser')?.value,
      target_date: formGroup.get('TargetDate')?.value,
    };
  }

  resToForm(response: any): any {
    return {
      Comment: response.comment,
      Title: response.title,
      Description: response.description,
      ResponsibleUser: response.responsible_user?.id,
      TargetDate: response.target_date,
    };
  }

    resToDetails(response: any): AuditInsightDraftReportFindingActionPlanDetailsDTO {
    return {
      id: response.id,
      title: response.title,
      audit_draft_finding_id: response.audit_draft_finding_id,
      description: response.description,
      responsible_user: this.userConv.resObjToUserMinimalDTO(response.responsible_user),
      target_date: response.target_date,
    };
  }

  formGroupToFindingChallengeForm(formGroup: FormGroup): AuditInsightDraftReportFindingChallengeFormDTO {
    return {
      comment: formGroup.get('Comment')?.value,
      type: "DR",
    };
  }
  
  
  
}
