import { Injectable } from '@angular/core';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import {
  AuditDraftReportDetailsDTO,
  AuditDraftReportDocumentDTO,
  AuditDraftReportFormDTO,
  AuditDraftReportListDTO,
} from '../dto/audit-draft-report.dto';
import { AuditChecklistFindingConversion } from './audit-checklist-finding.conversion';
import { MasterConversion } from '../../../../shared/conversion/master.conversion';

@Injectable({
  providedIn: 'root',
})
export class AuditDetailsDraftReportConversion {
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
    private tableConv: TableConversion,
    private masterConv: MasterConversion,
    private auditChecklistFindingConv: AuditChecklistFindingConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any): AuditDraftReportListDTO {
    return {
      // TODO: not currently needed
    };
  }
  formGroupToForm(formGroup: FormGroup): AuditDraftReportFormDTO {
    return {
      report_date: formGroup.get('ReportDate')?.value,
      executive_summary: formGroup.get('ExecutiveSummary')?.value,
      conclusion: formGroup.get('Conclusion')?.value,
    };
  }
  resToForm(response: any): any {
    return {
      ReportDate: response.report_date,
      ExecutiveSummary: response.executive_summary,
      Conclusion: response.conclusion,
    };
  }
  resToDetails(response: any): AuditDraftReportDetailsDTO {
    return {
      id: response.id,
      draft_report_status: this.masterConv.resToDetails(
        response.audit_draft_report_status
      ),
      internal_review_submitted_by: this.userConv.resObjToUserMinimalDTO(
        response.internal_review_submitted_by
      ),
      internal_review_submitted_at: response.internal_review_submitted_at,
      auditee_review_submitted_by: this.userConv.resObjToUserMinimalDTO(
        response.auditee_review_submitted_by
      ),
      review_users: response.review_users.length > 0 ? response.review_users.map((user:any) => this.userConv.resObjToUserMinimalDTO(user.user)): [],
      review_user: response.review_users.length > 0 ? this.userConv.resObjToUserMinimalDTO(response.review_users[0].user) : null,
      auditee_review_submitted_at: response.auditee_review_submitted_at,
    };
  }

  resToDraftReportDocument(response: any): AuditDraftReportDocumentDTO {
    return {
      id: response.id,
      audit_id: response.audit_id,
      title: response.title,
      reference_number: response.reference_number,
      report_date: response.report_date,
      prepared_by: this.userConv.resObjToUserMinimalDTO(response.prepared_by),
      prepared_at: response.prepared_at,
      executive_summary: response.executive_summary,
      auditors: response.auditors.map((user: any) =>
        this.userConv.resObjToUserMinimalDTO(user.user)
      ),
      conclusion: response.conclusion,
      team_lead: this.userConv.resObjToUserMinimalDTO(response.team_lead),
      draft_findings: response.draft_findings.map((finding: any) =>
        this.auditChecklistFindingConv.resToDetails(finding)
      ),
    };
  }
}
